import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import BgImage from '../components/BgImage'
import "./home.css"

const IndexPage = ({ data }) => {
  const {
    frontmatter: { title, description, backgroundImage, cta, subtitle, projects }
  } = data.markdownRemark
  return (
    <Layout title={title}>
      <div className="home">
        <section className="home-main-cta">
          <BgImage
            title="astronaut"
            src={backgroundImage.childImageSharp.base64}
            overlayColor="#04040454"
          >
            <div className="black-overlay text-white text-center rgba-stylish-strong py-5 px-4">
              <div className="content-box py-5">
                <h1 className="h5"><i className="fas fa-camera-retro"></i> {title}</h1>
                <h2 className="card-title h2 my-4 py-2">{description}</h2>
                <button type="button" className="btn btn-light">
                  <Link className="font-weight-bold" to={cta.link} rel="next">{cta.text}</Link>
                </button>
              </div>
            </div>
          </BgImage>
        </section>
        <section className="mt-4">
          <div className="album py-3">
            <p className="h3 text-center">{subtitle}</p>
            <div className="container mt-5">
              <div className="row">
                {projects.length > 0 && renderProjects(projects)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout >
  )
}

const renderProjects = (projects) => {
  return (
    projects.map(({ image, title, description, website, code, video, year }, i) => {
      return (
        <div key={i} className="col-md-6 my-3">
          <div className="card shadow h-100">
            {image && (
              <Img
                fluid={image.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={title}
                className="bd-placeholder-img card-img-top portfolio-image"
              />
            )}
            <div className="card-body">
              <h3 className="card-title">{title}</h3>
              <p className="card-text">{description}</p>
            </div>
            <div className="card-footer">
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-group">
                  {
                    website && (
                      <button type="button" className="btn btn-sm">
                        <a className="text-dark" href={website} target="_blank" rel="noopener noreferrer">Ver web</a>
                      </button>
                    )
                  }
                  {
                    code && (
                      <button type="button" className="btn btn-sm">
                        <a className="text-dark" href={code} target="_blank" rel="noopener noreferrer">Ver Github</a>
                      </button>
                    )
                  }
                  {
                    video && (
                      <button type="button" className="btn btn-sm">
                        <a className="text-dark" href={video} target="_blank" rel="noopener noreferrer">Ver vídeos relacionados</a>
                      </button>
                    )
                  }
                </div>
                <small className="text-muted">{year}</small>
              </div>
            </div>
          </div>
        </div>
      )
    })
  )
}

export const query = graphql`
  query Home {
    markdownRemark(frontmatter: {type: {eq: "home"}}) {
      frontmatter {
        title
        description
        backgroundImage {
          relativePath
          childImageSharp {
            base64: sizes(base64Width: 1000, quality: 80) {
              base64
            }
          }
        }
        cta {
          text
          link
        }
        subtitle
        projects {
          title
          description
          website
          code
          video
          year
          image {
            relativePath
            childImageSharp {
            fluid(quality: 90, maxHeight: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          }
        }
      }
    }
  }
`
export default IndexPage

