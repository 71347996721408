import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
//https://markoskon.com/gatsby-background-image-example/

const Parent = styled.div`
  position: relative;
  background-color: ${({ bc }) => bc};
  height: 75vh;
`;

const FakeBgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height};
  object-fit: cover;
  height: 75vh;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: "object-fit: cover !important; object-position: 0% 0% !important;";
  }

  @media screen and (max-width: 600px) {
    height: ${({ mobileHeight }) => mobileHeight};
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const BgImage = ({
  src,
  title,
  height,
  mobileHeight,
  overlayColor,
  children,
  className,
}) => (
    <Parent bc={overlayColor}>
      <FakeBgImage
        src={src.base64}
        title={title}
        height={height}
        mobileHeight={mobileHeight}
      />
      <Content className={className}>{children}</Content>
    </Parent>
  );
BgImage.propTypes = {
  src: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
  overlayColor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};
BgImage.defaultProps = {
  height: null,
  mobileHeight: null,
  overlayColor: "transparent",
  children: null,
  className: null,
};

export default BgImage;